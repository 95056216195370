<!--
 * @Author: 刘格优
 * @Date: 2020-03-08 18:57:23
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-08-26 18:15:20
 -->

<template>
  <div class="content">
    <div>如何邀请</div>
    <p>1、好友通过您分享的链接或者二维码注册成功后，即可成为您的好友合伙人</p>
    <p>2、邀请的好友仅限未有保险展业资质的用户</p>
    <div>邀请奖励</div>
    <p>1、时间是长期有效</p>
    <p>
      2、每邀请一位好友成功认证展业资质，即可获得 一次指定产品优惠券的邀请奖励
    </p>
    <p>
      3、您的好友合伙人在平台上成功出单，您好友合伙人获得相应产品的推广费，<span>您也可获得您好友合伙人出单保费的30%</span>
    </p>
    <p>
      4、好友合伙人展业资质认证后，邀请人可获得产品指
      定优惠券一张，发至您的奖励券账户，请到平台【我的】-【我的奖励券】查收
    </p>
    <p>5、退保退单将扣回相应奖励</p>
    <p>
      6、本方案的最终解释权归【汇立天下】平台所
      有，如存在恶意刷单，作弊等行为，我们有权
      进行以上奖励扣除，也将封禁相应账户，并追
      究责任，有疑问可添加平台微信客服<span>【KFHLTX001】</span>咨询
    </p>
    <div class="kfinfo">
      <p class="top">扫码添加客服</p>
      <img :src="ossurl + kfimg"
           alt="" />
      <p class="topbto">客服微信号 KFHLTX001</p>
    </div>
  </div>
</template>

<script>
import { getStorage } from "@/lib/util";
export default {
  data () {
    return {
      kfimg: "",
      kfphone: "",
      ossurl: ""
    };
  },
  created () {
    this.ossurl = getStorage("ossurl", "");
    let kfinfo = getStorage("kfInfo", "");
    for (let val in kfinfo) {
      if (val == "ketel") {
        this.kfphone = kfinfo["ketel"];
      } else {
        this.kfimg = kfinfo["kfqrcode"];
      }
    }
  },
  methods: {}
};
</script>
<style scoped lang="stylus">
.content {
  padding: 0.5rem;
  height: 100%;
  overflow-y: auto;

  div {
    color: color-primary;
    font-size: 0.55rem;
    font-weight: 600;
    padding: 1rem 0 0.5rem;
  }

  p {
    color: #676767;
    padding: 0.3rem;

    span {
      color: #383838;
      font-size: 0.45rem;
      font-weight: 600;
    }
  }

  .kfinfo {
    width: 100%;
    text-align: center;

    .top {
      font-size: 0.3rem;
    }

    .topbto {
      font-size: 0.35rem;
    }

    img {
      width: 30%;
    }
  }
}
</style>
